
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import newStudentApi from './api/newStudentApi';
import loginapi from './api/login';
import OfferedCoursesT from './OfferedCoursesT';
import { APISERVERURL } from './APIServerURL';



function ViewFormFillup({accessToken,setAccessToken,setRefreshFailed}) {
    const [isloading,setIsloading]=useState(false);
    const [formFillupStatistics,setFormFillupStatistics]=useState(null);

    function downloadFile(fname)
    {
      fetch(`${APISERVERURL}/pdf/${fname}`).then((response) => {
        response.blob().then((blob) => {
         
            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);
                 
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fname;
            alink.click();
            setIsloading(false);
        });
      });
    }
    async function getCSV(e)
    {
      e.preventDefault();
      try{
        setIsloading(true);
        const response= await api.post('/getCSV',{},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        alert(response.data);
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          try{       
            const response= await api.post('/getCSV',{},{headers:{'Authorization':`Bearer ${nat}`}});    
            downloadFile(response.data);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
        }

      }
    }

    async function getCSV2(e)
    {
      e.preventDefault();
      try{
        setIsloading(true);
        const response= await newStudentApi.post('/downloadCSV',{},{headers:{'Authorization':`Bearer ${accessToken}`}});  
        alert(response.data);  
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          try{       
            const response= await newStudentApi.post('/downloadCSV',{},{headers:{'Authorization':`Bearer ${nat}`}});    
            downloadFile(response.data);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
        }

      }
    }

    async function getCSV3(e)
    {
      e.preventDefault();
      try{
        setIsloading(true);
        const response= await api.post('/getAllStudentsCSV',{},{headers:{'Authorization':`Bearer ${accessToken}`}});  
        alert(response.data);  
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          try{       
            const response= await api.post('/getAllStudentsCSV',{},{headers:{'Authorization':`Bearer ${nat}`}});    
            downloadFile(response.data);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
        }

      }
    }

    async function getCSV4(e)
    {
      e.preventDefault();
      try{
        setIsloading(true);
        const response= await api.post('/getAllCourseCsv',{},{headers:{'Authorization':`Bearer ${accessToken}`}});  
        alert(response.data);  
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          try{       
            const response= await api.post('/getAllCourseCsv',{},{headers:{'Authorization':`Bearer ${nat}`}});    
            downloadFile(response.data);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
        }

      }
    }




    useEffect(()=>{
        const fetchAT=async ()=>{
            setIsloading(true);
          try{       
            //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
            const response=await api.post('/getFormFillupStatistics',{id:1},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
            //alert(JSON.stringify(response.data));
            if(response.data.statistics)
            setFormFillupStatistics(response.data.statistics);
            else
            setFormFillupStatistics(null);
            }
            catch(err)
            {
              try{
                const config = { withCredentials: true };
                const x=await loginapi.get('/getAccessToken',config);            
                const nat=x['data']['accessToken'];
                const ufn=x['data']['userFullName'];
                const un=x['data']['userName'];
                const lt=x['data']['loginType'];
                //alert(nat);
                setAccessToken(nat);
                setRefreshFailed(false);  
                        
               
                  if(lt.toLowerCase()==="admin")
                    {
                          try{       
                            //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                            const response=await api.post('/getFormFillupStatistics',{id:1},{headers:{'Authorization':`Bearer ${nat}`}});                            
                            //alert(JSON.stringify(response.data));
                            if(response.data.statistics)
                                setFormFillupStatistics(response.data.statistics);
                                else
                                setFormFillupStatistics(null);
                        }
                        catch(err)
                        {
                          alert('refresh failed unusual'+JSON.stringify(err));
                          setAccessToken('');
                          setRefreshFailed(true);  
                        }
                      }
                }
                catch(err)
                {
                  //alert('dd');
                  setAccessToken(''); 
                  setRefreshFailed(true);              
                }
            }         
             finally
                {
                    setIsloading(false);
                  //alert('app loaded'+accessToken);
                }
        }
        fetchAT();
      },[]);
  
    return (
      <main>
        <div className="fullWidth centeredDiv verticaldiv">
            <h3>VIEW FORM FILLUP DETAILS</h3>
        {formFillupStatistics && 
        <div className="hdivwrapcontainer centeredDiv width1000">
         <div className="hdivwrapcontainer-item">
                  <p>SUBMITTED</p>
                  <p className="infotext">{formFillupStatistics.totalSubmission}</p>
                  <button className="link-button"  onClick={(e)=>{getCSV(e)}}>DOWNLOAD</button>
         </div>
         <div className="hdivwrapcontainer-item">
                  <p>ACCEPTED</p>
                  <p className="infotext">{formFillupStatistics.accepted}</p>
                  <button className="link-button"  onClick={(e)=>{getCSV(e)}}></button>
         </div>

         <div className="hdivwrapcontainer-item">
                  <p>NEW REGISTRATION</p>
                  <p className="infotext">{"."}</p>
                  <button className="link-button"  onClick={(e)=>{getCSV2(e)}}>DOWNLOAD</button>
         </div>

         <div className="hdivwrapcontainer-item">
                  <p>getAllStudentsCSV</p>
                  <p className="infotext">{"."}</p>
                  <button className="link-button"  onClick={(e)=>{getCSV3(e)}}>DOWNLOAD</button>
         </div>

         <div className="hdivwrapcontainer-item">
                  <p>AllCOURSES</p>
                  <p className="infotext">{"."}</p>
                  <button className="link-button"  onClick={(e)=>{getCSV4(e)}}>DOWNLOAD</button>
         </div>
         
        </div>
        }
        </div>
      </main>
    )
  }
  
  export default ViewFormFillup